<template>
    <div id="editGatewayPage" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="editGateway"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="gtw_editBleGatewaySubTitle"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path d="M19.366142,13.9305937 L17.2619853,15.6656848 C15.9733542,14.1029531 14.0626842,13.1818182 11.9984835,13.1818182 C9.94104045,13.1818182 8.03600715,14.0968752 6.74725784,15.6508398 L4.64798148,13.9098472 C6.44949126,11.7375997 9.12064835,10.4545455 11.9984835,10.4545455 C14.8857906,10.4545455 17.5648042,11.7460992 19.366142,13.9305937 Z M23.5459782,10.4257575 L21.4473503,12.1675316 C19.1284914,9.37358605 15.6994058,7.72727273 11.9984835,7.72727273 C8.30267753,7.72727273 4.87785708,9.36900008 2.55893241,12.1563207 L0.462362714,10.4120696 C3.29407133,7.00838857 7.48378666,5 11.9984835,5 C16.519438,5 20.7143528,7.01399004 23.5459782,10.4257575 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                            <path d="M15.1189503,17.3544974 L13.0392442,19.1188213 L11.9619232,20 L10.9331836,19.1485815 L8.80489611,17.4431757 C9.57552634,16.4814558 10.741377,15.9090909 11.9984835,15.9090909 C13.215079,15.9090909 14.347452,16.4450896 15.1189503,17.3544974 Z" id="Oval-21" fill="#000000"></path>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("gtw_editBleGateway") }}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form class="kt-form" novalidate="novalidate">
                                            <div class="kt-portlet__body">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group validated">
                                                            <label for="editGatewayForm_nameInput">{{ $t("gtw_bleGatewayNameLabel") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                </div>
                                                                <input v-model="vName" @input="$v.vName.$touch()" @keyup="onChangeForm" type="text" class="form-control" id="editGatewayForm_nameInput" :placeholder="$t('gtw_bleGatewayEnterName')" />
                                                                <div v-if="!$v.vName.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <div v-else-if="!$v.vName.minLen" class="invalid-feedback">
                                                                    {{ $t("error_minLengthMsg", [$v.vName.$params.minLen.min]) }}
                                                                </div>
                                                            </div>
                                                            <span class="form-text text-muted"> {{ $t("gtw_bleGatewayNameExample") }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group validated">
                                                            <label for="editGatewayForm_modelInput">{{ $t("gtw_bleGatewayModelLabel") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-hdd-o"></i></span>
                                                                </div>
                                                                <!-- <select id="editGatewayForm_modelInput" @change="onChangeForm" class="form-control" v-model="vModel" v-bind:class="{ emptyColor: vModel == '' }">
                                                                    <option value="" disabled selected>{{ $t("gtw_bleGatewayEnterModel") }}</option>
                                                                    <option v-for="(model, index) in gatewayModels" :key="index" :value="model">{{ model }}</option>
                                                                </select> -->
                                                                <div id="editGatewayModel" class="gateway-form-model-input">
                                                                    <select id="editGatewayForm_modelInput" class="form-control kt-select2">
                                                                        <option></option>
                                                                    </select>
                                                                </div>
                                                                <div v-if="!$v.vModel.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                            </div>
                                                            <span class="form-text text-muted">{{ $t("gtw_bleGatewayModelExample") }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group validated">
                                                            <label for="editGatewayForm_bleMacAddressInput">{{ $t("gtw_bleGatewayBleMacAddressLabel") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-code"></i></span>
                                                                </div>
                                                                <input v-model="vBleMacAddress" @input="$v.vBleMacAddress.$touch()" @keyup="onChangeForm" type="text" class="form-control toUppercase" id="editGatewayForm_bleMacAddressInput" :placeholder="$t('gtw_bleGatewayEnterMacAddress')" />
                                                                <div v-if="!$v.vBleMacAddress.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <div v-else-if="!$v.vBleMacAddress.notEqualToWifiMacAddress && $v.vBleMacAddress.$dirty" class="invalid-feedback">
                                                                    {{ $t("error_bleAndWifiMacAddressCannotBeEqual") }}
                                                                </div>
                                                                <div v-else-if="!$v.vBleMacAddress.macAddress" class="invalid-feedback">
                                                                    {{ $t("error_invalidMacAddress") }}
                                                                </div>
                                                            </div>
                                                            <span class="form-text text-muted">{{ $t("gtw_bleGatewayBleMacAddressExample") }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group validated">
                                                            <label for="editGatewayForm_wifiMacAddressInput">{{ $t("gtw_bleGatewayWifiMacAddressLabel") }} <span v-if="this.isArubaModel">*</span></label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-code"></i></span>
                                                                </div>
                                                                <input v-model="vWifiMacAddress" @input="$v.vWifiMacAddress.$touch()" @keyup="onChangeForm" type="text" class="form-control toUppercase" id="editGatewayForm_wifiMacAddressInput" :placeholder="$t('gtw_bleGatewayEnterWifiMacAddress')" />
                                                                <div v-if="!$v.vWifiMacAddress.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <div v-else-if="!$v.vWifiMacAddress.macAddress" class="invalid-feedback">
                                                                    {{ $t("error_invalidMacAddress") }}
                                                                </div>
                                                                <div v-else-if="!$v.vWifiMacAddress.notEqualToBleMacAddress && $v.vWifiMacAddress.$dirty" class="invalid-feedback">
                                                                    {{ $t("error_bleAndWifiMacAddressCannotBeEqual") }}
                                                                </div>
                                                            </div>
                                                            <span class="form-text text-muted">{{ $t("gtw_bleGatewayWifiMacAddressExample") }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group validated">
                                                            <label for="editGatewayForm_ipAddressInput">{{ $t("gtw_bleGatewayIpAddressLabel") }}</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-map-marker"></i></span>
                                                                </div>
                                                                <input v-model="vIpAddress" @input="$v.vIpAddress.$touch()" @keyup="onChangeForm" type="text" class="form-control" id="editGatewayForm_ipAddressInput" :placeholder="$t('gtw_bleGatewayEnterIpAddress')" />
                                                                <div v-if="!$v.vIpAddress.ipAddress" class="invalid-feedback">
                                                                    {{ $t("error_invalidIpAddress") }}
                                                                </div>
                                                                <div v-else-if="$v.vIpAddress.$invalid" class="invalid-feedback">
                                                                    {{ $t("error_invalidIpAddress") }}
                                                                </div>
                                                                <div v-else-if="$v.vIpAddress.$error" class="invalid-feedback">
                                                                    {{ $t("error_invalidIpAddress") }}
                                                                </div>
                                                            </div>
                                                            <span class="form-text text-muted">{{ $t("gtw_bleGatewayIpAddressExample") }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="editGatewayForm_softwareVersionInput">{{ $t("gtw_bleGatewaySoftwareVersionLabel") }}</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-simplybuilt"></i></span>
                                                                </div>
                                                                <input v-model="vSoftwareVersion" @keyup="onChangeForm" type="text" class="form-control" id="editGatewayForm_softwareVersionInput" :placeholder="$t('gtw_bleGatewayEnterSoftwareVersion')" />
                                                            </div>
                                                            <span class="form-text text-muted">{{ $t("gtw_bleGatewaySoftwareVersionExample") }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="editGatewayForm_groupNameInput">{{ $t("gtw_bleGatewayGroupNameLabel") }}</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-clone"></i></span>
                                                                </div>
                                                                <input v-model="vGroupName" @keyup="onChangeForm" type="text" class="form-control" id="editGatewayForm_groupNameInput" :placeholder="$t('gtw_bleGatewayEnterGroupName')" />
                                                            </div>
                                                            <span class="form-text text-muted">{{ $t("gtw_bleGatewayGroupNameExample") }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="editGatewayForm_tagsInput">{{ $t("gtw_bleGatewayKeywordsLabel") }}</label>
                                                            <div class="input-group">
                                                                <!--     <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-tags"></i></span>
                                                                </div>
                                                            -->
                                                                <vue-tags-input id="editGatewayForm_tagsInput" :add-on-key="[13, ' ', ',', ';']" :placeholder="$t('gtw_bleGatewayKeywordsPlaceholder')" v-model="vKeyword" :tags="keywords" @tags-changed="tagChanged" />
                                                            </div>
                                                            <span class="form-text text-muted">{{ $t("gtw_bleGatewayKeywordsExample") }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    <button id="editGatewayCreateButton" @click="onUpdateButton" type="button" class="btn btn-brand kt-margin-r-5" :disabled="$v.$invalid || !isFormDataChanged">
                                                        <i class="la la-save"></i>
                                                        {{ $t("common_saveChanges") }}
                                                    </button>
                                                    <button id="editGatewayCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                </div>
                            </div>

                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, macAddress, sameAs, not, ipAddress, minLength, requiredIf } from "vuelidate/lib/validators";
import i18n from "../../i18n";
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";
import { VueTagsInput } from "@johmun/vue-tags-input";

const _gatewaysName = require("../../constants/gateways");
const _arubaGatewaysName = require("../../constants/arubaGateways");

export default {
    data() {
        return {
            vName: "",
            vBleMacAddress: "",
            vWifiMacAddress: "",
            vModel: "",
            vSoftwareVersion: "",
            vGroupName: "",
            vIpAddress: "",
            vKeyword: "",
            keywords: [],
            siteId: this.$route.params.siteId,
            gatewayId: this.$route.params.gatewayId,
            isFormDataChanged: false,
            // gatewayModels: _gatewaysName.list
            oldBLEGateway: {},
            isArubaModel: false
        };
    },
    created: function() {
        console.log("Component(editGateway)::created() - called");
        const payload = {
            siteId: this.siteId,
            gatewayId: this.gatewayId
        };
        this.getBLEGatewayById(payload);
    },
    mounted: function() {
        console.log("Component(editGateway)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(editGateway)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        vName: {
            required,
            minLen: minLength(2)
        },
        vModel: {
            required
        },
        vBleMacAddress: {
            required,
            macAddress: macAddress(),
            notEqualToWifiMacAddress: not(sameAs("vWifiMacAddress"))
        },
        vWifiMacAddress: {
            required: requiredIf(function () {
                return this.isArubaModel;
            }),
            macAddress: macAddress(),
            notEqualToBleMacAddress: not(sameAs("vBleMacAddress"))
        },
        vIpAddress: {
            ipAddress
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentBLEGateway: function(gateway) {
            console.log("Component(editGateway)::watch currentBLEGateway - called");
            if (gateway) {
                this.siteId = this.$route.params.siteId;
                this.gatewayId = this.$route.params.gatewayId;
                this.vName = gateway.name;
                this.oldBLEGateway.name = this.vName;
                this.vBleMacAddress = gateway.bleMacAddress;
                this.oldBLEGateway.bleMacAddress = this.vBleMacAddress;
                this.vWifiMacAddress = gateway.wifiMacAddress;
                this.oldBLEGateway.wifiMacAddress = this.vWifiMacAddress;
                this.vModel = gateway.model;
                this.oldBLEGateway.model = this.vModel;
                this.vSoftwareVersion = gateway.softwareVersion;
                this.oldBLEGateway.softwareVersion = this.vSoftwareVersion;
                this.vGroupName = gateway.groupName;
                this.oldBLEGateway.groupName = this.vGroupName;
                this.vIpAddress = gateway.ipAddress;
                this.oldBLEGateway.ipAddress = this.vIpAddress;
                if (gateway.hasOwnProperty("keywords") && gateway.keywords != null && gateway.keywords.hasOwnProperty("list")) {
                    this.keywords = _.map(gateway.keywords.list, item => {
                        return { text: item };
                    });
                } else {
                    this.keywords = [];
                }
            }

            setTimeout(() => {
                this.initGatewayModelsSelect2();
            }, 200);
        },
        currentSite: function(site) {
            console.log("Component(editGateway)::watch currentSite - called " + site);
        },
        vModel(model) {
            this.isArubaModel = _arubaGatewaysName.list.includes(this.vModel);
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([ "currentBLEGateway", "currentSite"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getBLEGatewayById", "updateBLEGateway"]),

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(editGateway)::onCancelButton() - called");
            this.$router.push({ name: "gateways" });
        },

        onUpdateButton() {
            console.log("Component(editGateway)::onUpdateButton() - called");
            const data = {
                siteId: this.siteId,
                gatewayId: this.gatewayId,
                name: this.vName,
                bleMacAddress: this.vBleMacAddress,
                wifiMacAddress: this.vWifiMacAddress,
                model: this.vModel,
                softwareVersion: this.vSoftwareVersion,
                groupName: this.vGroupName,
                ipAddress: this.vIpAddress,
                keywords: _.map(this.keywords, "text")
            };
            this.updateBLEGateway(data);
        },

        onChangeForm() {
            if (
                this.oldBLEGateway.name === this.vName &&
                this.oldBLEGateway.model === this.vModel && 
                this.oldBLEGateway.bleMacAddress === this.vBleMacAddress &&
                this.oldBLEGateway.wifiMacAddress === this.vWifiMacAddress &&
                this.oldBLEGateway.softwareVersion === this.vSoftwareVersion &&
                this.oldBLEGateway.groupName === this.vGroupName &&
                this.oldBLEGateway.ipAddress === this.vIpAddress
            ) {
                return this.isFormDataChanged = false;
            }
            this.isFormDataChanged = true;
        },
        tagChanged(newKeywords) {
            this.onChangeForm();
            this.keywords = newKeywords;
        },

        getDataGatewaymodels() {
            const gatewayModels = [];
            const stellarModels = _.map(_gatewaysName.list, gatewayModel => {
                return {id: gatewayModel, text: gatewayModel};
            })
            let arubaModels = [];

            if (this.currentSite && this.currentSite.BLEGatewaysType === "OMNIACCESS_OEM") {
                arubaModels = _.map(_arubaGatewaysName.list, gatewayModel => {
                    return {id: gatewayModel, text: gatewayModel};
                });
            }

            gatewayModels.push({
                text: i18n.t("common_omniAsset_stellar"),
                children: stellarModels
            });

            if (arubaModels.length > 0) {
                gatewayModels.push({
                    text: i18n.t("common_omniAsset_aruba"),
                    children: arubaModels
                });
            }

            return gatewayModels;

        },

        initGatewayModelsSelect2() {
            var self = this;
            const dataGatewayModels = this.getDataGatewaymodels();
            const gatewayModelSelector = $("#editGatewayForm_modelInput")
 
            commonVueHelper.destroySelect2(gatewayModelSelector);
            gatewayModelSelector
                .select2({
                    allowClear: true,
                    dropdownParent: $("#editGatewayModel"),
                    placeholder: i18n.t("gtw_bleGatewayEnterModel"),
                    width: "100%",
                    language: {
                        noResults: function() {
                            return i18n.t("common_noResultsFound");
                        }
                    },
                    data: dataGatewayModels
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function(event) {
                    self.vModel = $(this).val();
                    if (!event.params || (event.params && !event.params.initialInit)) {
                        // Trigger change form only if its a manual action from the user, not the initial init !
                        self.onChangeForm();
                    }
                });
            if (this.currentBLEGateway && this.currentBLEGateway.model) {
                gatewayModelSelector.val(this.currentBLEGateway.model);
            } else {
                gatewayModelSelector.val(null);
            }
            gatewayModelSelector.trigger({ type: "change", params: { initialInit: true } });
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle,
        "vue-tags-input": VueTagsInput
    },

    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        // --
        commonVueHelper.displayConfirmModalOnLeavePage(this, "EditGateway", "update", this.isFormDataChanged, to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.emptyColor {
    color: #b2b5ca;
}

#editGatewayForm_tagsInput {
    width: 100%;
    max-width: 100%;
}

.gateway-form-model-input {
    position: relative;
    width: 1% !important;
    margin-bottom: 0;
    flex: 1 1 auto;
}
</style>
